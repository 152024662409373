import React from 'react';
import styled from 'styled-components';

import { tablets } from '@bscom/styling/breakpoints';
import { Markdown } from '@bscom/components/Markdown';
import { Background } from '@bscom/components/Background';
import { ButtonsFromType } from '@bscom/components/Buttons';
import { HeaderSubheader } from '@bscom/components/HeaderSubheader';

const MAX_WIDTH = 730;

const ContentWrapper = styled.div`
  margin: 0 auto;
  padding: 74px 28px 94px;

  @media (${tablets}) {
    padding: 148px 28px;
  }

  ${({ isCentered }) => `
    max-width: ${isCentered ? `${MAX_WIDTH}px` : '100%'};
    text-align: ${isCentered ? 'center' : 'left'};
  `}
`;

const ContentHeader = styled.div`
  margin-bottom: 36px;

  @media (${tablets}) {
    margin-bottom: 44px;
  }

  div {
    max-width: 100%;
  }
`;

const ContentText = styled(Markdown)`
  margin-bottom: 36px;

  @media (${tablets}) {
    margin-bottom: 44px;
  }
`;

export const Headline = ({
  content = '',
  headline = {},
  background,
  isCentered = true,
  buttonsCollection = {},
}) => {
  const buttons =
    buttonsCollection?.items?.length > 0
      ? buttonsCollection.items.map(({ link, ...button }) => ({
          ...button,
          href: link,
        }))
      : [];

  return (
    <Background {...background}>
      <ContentWrapper isCentered={isCentered}>
        <ContentHeader>
          <HeaderSubheader large {...headline} />
        </ContentHeader>
        <ContentText>{content}</ContentText>
        <ButtonsFromType buttons={buttons} />
      </ContentWrapper>
    </Background>
  );
};

export default Headline;
